











































































































import { Component, Vue } from "vue-property-decorator";
import { IDivision } from "../../common-app/models/Division";
import { IDistrict } from "../../common-app/models/District";
import { IThana } from "../../common-app/models/Thana";
import UniversalService from "../../common-app/service/UniversalService";
import AuthUser from "../../global-config/AuthUser";

@Component
export default class cover extends Vue {
  public divisions: IDivision[] = [];
  public districts: IDistrict[] = [];
  public districtsList: IDistrict[] = [];
  public thanas: IThana[] = [];
  public searchKey: any;
  public autocompleteList: any[] = [];
  public autocompleteAllList: any[] = [];
  public typeModel: any = "Doctor";
  public locationModel: any = "";
  public spModel: any = "";
  public isAuthorize: boolean = false;
  public isDoctorRequest: boolean = false;
  public $refs: {
    type: HTMLElement;
    location: HTMLElement;
    spe: HTMLElement;
  };

  created() {
    this.isAuthorize = AuthUser.isLoggednIn() || false;
    UniversalService.GetAddress().then((res) => {
      this.districts = res.data.districts;
      this.districtsList = res.data.districts;
    });
    this.isDoctorRequest = AuthUser.getIsDoctorRequest() || false;
  }

  change(id: any) {
    UniversalService.autoComplete(id).then((res) => {
      this.autocompleteAllList = res.data;
      this.autocompleteList = this.autocompleteAllList.filter((s) => !s.isDoctor);
    });
  }
  changeSP(e: any) {
    this.searchKey = e;
  }

  searchSpecialization(event: string) {
    if (event !== "") {
      this.autocompleteList = this.autocompleteAllList.filter((a) => a.text.toLowerCase().startsWith(event.toLowerCase()));
    } else {
      this.autocompleteList = this.autocompleteAllList.filter((s) => !s.isDoctor);
    }
  }

  enterFunction(event: string) {
    if (event !== "") {
      this.districts = this.districtsList.filter((dis) => dis.name.toLowerCase().startsWith(event.toLowerCase()));
    } else {
      this.districts = this.districtsList;
    }
  }
  search() {
    if (this.searchKey) {
      if (this.searchKey.isDoctor) {
        this.$router.push({
          name: "details",
          params: { id: this.searchKey.id },
        });
      } else {
        this.$router.push({
          name: "doctor-list",
          query: {
            type: this.typeModel,
            distId: this.locationModel,
            id: this.searchKey.id,
            name: this.searchKey.text,
          },
        });
      }
    } else if (this.typeModel == "") {
      //this.$refs.type.focus();
      const select: any = this.$refs.type;
      select.$refs.search.focus();
    } else if (this.locationModel == "") {
      const location: any = this.$refs.location;
      location.$refs.search.focus();
    } else {
      const spes: any = this.$refs.spe;
      spes.$refs.search.focus();
    }
  }
}
