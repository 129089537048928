







































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import DoctorService from "../../doctor-app/service/DoctorService";
import HospitalService from "../../diagnostic-app/service/HospitalService";
import { IDoctorDetailsViewModel } from "../../doctor-app/models/DoctorDetailsViewModel";
// import { IDiagnosticDetailsViewModel } from "../../diagnostic-app/models/DiagnosticDetailViewModel";
import { IHospitalDetail } from "../../diagnostic-app/models/HospitalDetail";

@Component
export default class featuredListing extends Vue {
  public doctors: IDoctorDetailsViewModel[] = [];
  public diagnostics: IHospitalDetail[] = [];

  created() {
    DoctorService.FeatureDrList().then((res) => {
      this.doctors = res.data.slice(0, 5);
      // console.log(this.doctors);
    });

    HospitalService.GetTopList().then((result) => {
      this.diagnostics = result.data;
      this.diagnostics = this.diagnostics.filter((s) => s.isActive == 1);
    });
  }
}
