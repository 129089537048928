





























































import { Component, Vue } from "vue-property-decorator";
import AuthUser from "../../global-config/AuthUser";

@Component
export default class Service extends Vue {
  public isAuthorize: boolean = false;

  created() {
    this.isAuthorize = AuthUser.isLoggednIn() || false;
  }
}
