








import { Component, Vue } from "vue-property-decorator";
// @ is an alias to /src
import cover from "@/website/components/cover.vue";
import features from "@/website/components/features.vue";
import Service from "@/website/components/Service.vue";
import featuredListing from "@/website/components/featuredListing.vue";
import howItWorks from "@/website/components/howItWorks.vue";
import Footer from "@/website/components/footer.vue";

@Component({
  components: {
    cover,
    features,
    featuredListing,
    howItWorks,
    Service
  }
})
export default class Home extends Vue {}
